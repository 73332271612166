<div class="home">

    <div class="imageContainer">
        <img class="img-1" src="assets/images/derechodmg.jpg">
        <img class="img-2" src="assets/images/treedmg.jpg"> 
    </div>
    
    <div class="servicesContainer">
        <div class="tree-removal">
            <h1>Tree Removal</h1>
            <p>
                If a tree falls on your house, and nobody sees it, it definitely still makes a sound. 
                Let us get rid of it for you!<br>
                We'll clean up any unwanted branches
            </p>
            <a href="./quote-form">Request a quote</a>
        </div>
        <div class="tree-trimming">
            <h1>Tree Trimming</h1>
            <p>
                Trimming a damgerous limb can save you a big headache later. <br>
                Our experts can reduce the risk of future damage to your property
            </p>
            <a href="./quote-form">Request a quote</a>
        </div>
        <div class="snow-removal">
            <h1>Snow Removal</h1>
            <p>
                Tired of that fluffy white bullshit? We gotchu - <br>
                We also provide snow plowing services. 
            </p>
            <a href="./quote-form">Request a quote</a>
        </div>
    
        <img class="tree-removal-img" src="assets/images/tree-removal.jpg"> 
        <img class="tree-trimming-img" src="assets/images/tree-trimming.jpg"> 
        <img class="snow-removal-img" src="assets/images/snow-removal.jpg"> 
    
    </div>

    <div class="aboutContainer">
        <div class="info">
            <h2>Expert Tree Care Professionals</h2>
            <p>
                We're a group of hardworking tree experts located in the Cedar Rapids area.
                We're fully insured and bonded, so you never have to worry.
                <br>
                <br>
                <br>
                Are you a veteran? Thank you for your service - enjoy 10% off any services.
                <br>
                <br>
                Request a free quote for any of our services using any of the links on this page, or contact us directly at the information below.
            </p>

        </div>

        <div class="radius">
            <h2>Serving anyone within a 60 mile radius of lisbon</h2>
            <img class="radiusMap" src="assets/images/circlemap.jpg"> 
        </div>
    </div>

</div>
