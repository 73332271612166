<div class="header">

    <div class="titlebar">

        <a href="/">
            <img src="assets/images/ats-logo-cropped.png" class="ats-logo" alt="american tree logo">
        </a>
    
        <div class="contact">
            Contact us Today!
            <br>
            All Service Quotes are FREE
            <br>
            <br>
            <div class="phone">(319) 540.4778</div>
            <div class="email">sean.ats@outlook.com</div>
        </div>
    
    </div>
    
    <div class="services">
        <div class="motto">Servicing All Your Tree Needs</div>
        <div class="bonded">Fully Insured and Bonded</div>
        
    </div>

</div>

