<div class="main">
    
    <app-header></app-header>

    <router-outlet></router-outlet>
    
    <app-footer></app-footer>

</div>

