<form #f="ngForm" (ngSubmit)="onSubmit(f)" action="https://www.google.com/">

    <label for="service-type-dropdown">Service Type</label>
    <select name="service-type" id="service-type-dropdown">
        <option value="Tree Removal">Tree Removal</option>
        <option value="Tree Trimming">Tree Trimming</option>
        <option value="Snow Removal">Snow Removal</option>
    </select>

    <div class="name">

        <label for="first-name">First Name</label>
        <input type="text" id="first-name" name="first-name">
    
        <label for="last-name">Last Name</label>
        <input type="text" id="last-name" name="last-name">
    </div>

    <div class="contact">

        <label for="phone">Phone</label>
        <input type="text" id="phone" name="phone">
    
        <label for="email">Email:</label>
        <input type="email" id="email" name="email">
    </div>

    <div class="location">
        <label for="address">Address</label>
        <input type="text" id="address" name="address">

        <label for="city">City</label>
        <input type="text" id="city" name="city">

        <label for="state">State</label>
        <input type="text" id="state" name="state">

        <label for="zip">Zip Code</label>
        <input type="text" id="zip" name="zip">
        
    </div>

    <div class="message-block">
        <label for="message">Message</label>
        <input type="text" id="message" name="message" size="100em">
    </div>

    <input type="submit" id="submit" name="submit">

</form>

